/* Add a black background color to the top navigation */
.topnav {
  background-color: #8e24aa;
  text-decoration:none;
  overflow:hidden;
  display: flex;
  flex-direction: row;
  list-style-type:none;
  text-align: center;
}

/* Style the links inside the navigation bar */
.topnavlink a {
  color: black;
  background-color:white;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  display: inline;
  float: left;
  text-align: center;
  align-content: center;
  margin: 10px;
  outline: 4px solid purple;
  vertical-align: baseline;
  padding: 2px;
  width:100%;
}

/* Change the color of links on hover */
.topnavlink:hover a {
  background-color: #ddd;
  color: blue;
}

/* Add a color to the active/current link */
.topnavlink:active a{
  background-color: green;
  color: yellow;
}

.a{
 text-decoration:none;
}

.topnavbutton {
 align-content: center;
 position:relative;
 left:260px;
}
